import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { OwlModule } from 'ngx-owl-carousel';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
//import { GoTopButtonModule } from 'ng2-go-top-button';
import { ModalModule } from 'ngx-bootstrap';

import { CommonModule } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";

import { ApiService } from "./../shared/services/api.service";
import { Globals } from "./../globals";
import { ToastrModule } from 'ngx-toastr';

import { HomeComponent } from '../layout/home/home.component';
import { PrivacyComponent } from '../layout/privacy/privacy.component';
import { MessageComponent } from '../layout/message/message.component';
import { RegisterVetComponent } from '../layout/registervet/registervet.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { CustomService } from '../shared/services/custom.service';
import { SafePipe } from '../shared/pipes/safe-html.pipe';

import { NgxUiLoaderModule } from  'ngx-ui-loader';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PrivacyComponent,
    MessageComponent,
    RegisterVetComponent,
    SafePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CollapseModule.forRoot(),
    OwlModule,
    CommonModule,
    HttpClientModule,
    ScrollToModule.forRoot(),
    //GoTopButtonModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    FormsModule, 
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ToastrModule.forRoot(),
    NgxUiLoaderModule,

  ],
  exports:[
    //GoTopButtonModule,
    HttpClientModule,
    ToastrModule,
    SafePipe
  ],
  //providers: [ApiService, Globals],
  providers: [ApiService, Globals,CustomService,{    
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      // siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', // test site key      
      siteKey: '6LdkJpwUAAAAAP0ZuEQUNAFYzPmCq7aaj_sDniSW', // v2 live key
    } as RecaptchaSettings,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
