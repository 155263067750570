import { Injectable } from "@angular/core";
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpResponse
} from "@angular/common/http";

import { Observable } from "rxjs";
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/observable/throw';

import { Globals } from "./../../globals";

@Injectable()
export class ApiService {
    public _url: string = this.globals.APIUrlPath;
    public _contentType: string = "application/x-www-form-urlencoded";
    //public _contentType: string = "multipart/form-data";
    public _apiKey: string = this.globals.APIKEY;
    public _accept: string = "application/json";
    public _headerData = { "Content-Type": this._contentType, "Accept": this._accept, "apikey": this._apiKey };

    public reqHeader = new HttpHeaders(this._headerData);

    constructor(private http: HttpClient, private globals: Globals) {}

    
    doGET(urlAppend, append = true) {
        let url = '';
        if(append){
            url = this._url + urlAppend;
        } else{
            url = urlAppend;
        }
        return this.http.get(url);
    }

    /**
     * Country List
     * Method Get
     *
     */
    doPOST(urlAppend, bodyData, authorization = '', contentType = this._contentType): Observable<any> {
        
        if(authorization !== ''){
            let headerData = { "Content-Type": contentType, "Accept": this._accept, "apikey": this._apiKey, "Authorization": authorization };
            this.reqHeader = new HttpHeaders(headerData);
        }
        
        const request = {
            headers: this.reqHeader,
            url: this._url + urlAppend,
            params: bodyData
        };


        return this.http.post<any>(request.url, request.params, {
            headers: request.headers
        });
    }

    /*errorHandler(error: HttpErrorResponse){

     return Observable.throw(error.message || "Server Error");

  }*/

    /*doDELETE(urlAppend) {
  let url = _url+urlAppend;
  this.http.delete(url, {search}).subscribe(res => console.log(res));
  }*/
}
