import { Component, OnInit, HostListener, Inject, TemplateRef} from '@angular/core';
import { Globals } from "./../../globals";
import { ApiService } from "./../../shared/services/api.service";
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { trigger, state, transition, style, animate } from '@angular/animations'; 
import { DOCUMENT } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CustomService } from "../../shared/services/custom.service";
import { ActivatedRoute } from '@angular/router';
import { MustMatch } from '../../shared/must-match.validator';


declare var $: any;
declare var jquery: any;

@Component({
    selector: 'app-home',
    templateUrl: './registervet.component.html',
    styleUrls: ['./registervet.component.scss'],
    animations:[ 
      trigger('fade',
      [ 
        state('void', style({ opacity : 0})),
        transition(':enter',[ animate(300)]),
        transition(':leave',[ animate(500)]),
      ]
  )]
})

export class RegisterVetComponent implements OnInit {

    isCollapsed = true;    
    urlAppend: string;
    bodyData: any;
    isLoad: boolean = false;    
    logo;
    image;
    countryLists: any[];
    stateLists: any[];
    cityLists: any[];
    registerForm: FormGroup;
    package_id: any;
    showhide = true;    
    submitted = false;
    planDetails: any[];
   
    

    constructor(@Inject(DOCUMENT) document,
        private _apiService: ApiService,
        private toastr: ToastrService, //
        private router: Router,
        private globals: Globals,
        private modalService: BsModalService,
        private customService: CustomService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        
    ) {}

    ngOnInit() {
        
    
      
      this.package_id = this.route.snapshot.queryParamMap.get('plan');
      this.customService.windowTop();
      this.getCountryList();
      this.getPlanDetails(this.package_id);     
     

      this.registerForm = this.formBuilder.group({
        first_name: ['', Validators.required],
        last_name: ['',Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', Validators.required],
        mobile_number: ['', [Validators.required,Validators.minLength(10)]],
        country: ['', Validators.required],
        state: ['', Validators.required],
        city: ['', Validators.required],
        postcode: ['', Validators.required],
        address: ['',Validators.required]        
      }, {
        validator: MustMatch('password', 'confirmPassword')
      });
     
    }
    
    @HostListener('window:scroll', ['$event'])
    onWindowScroll(e) {
      if (window.pageYOffset > 130) {
        let element = document.getElementById('navbar');
        element.classList.add('sticky');
      } else {
        let element = document.getElementById('navbar');
          element.classList.remove('sticky'); 
      }
    }

  

     getCountryList(){  
      let urlAppend = "country-list";
      let bodyData = "";
      this._apiService
        .doPOST(urlAppend, bodyData.toString())
        .subscribe(
          (result: any) => {
            //console.log(result);
    
            if (result.responsecode == 200) {
              this.countryLists = result.country_list;
             // console.log(this.countryLists);
            } else if (result.responsecode == 201) {
              this.toastr.error(result.responsedetails, "Error");
            } else if (result.responsecode == 401) {
              this.toastr.error(
                this.globals.ServerErrorMsg,
                this.globals.ErrorText
              );
              
            } else {
              this.toastr.error(
                this.globals.ServerErrorMsg,
                this.globals.ErrorText
              );
              
            }
          },
          (err: HttpErrorResponse) => {
            this.toastr.error(
              this.globals.ErrorText,
              this.globals.ServerErrorMsg
            );
            
          }
        );
     }
     onChangeCountry(countryId: number) {
          
          let urlAppend = "state-list";
          let bodyData = "country_id="+countryId;
          this._apiService
            .doPOST(urlAppend, bodyData.toString())
            .subscribe(
              (result: any) => {
                //console.log(result);
    
                if (result.responsecode == 200) {
                  this.stateLists = result.state_list;
                // console.log(this.countryLists);
                } else if (result.responsecode == 201) {
                  this.toastr.error(result.responsedetails, "Error");
                } else if (result.responsecode == 401) {
                  this.toastr.error(
                    this.globals.ServerErrorMsg,
                    this.globals.ErrorText
                  );
                  
                } else {
                  this.toastr.error(
                    this.globals.ServerErrorMsg,
                    this.globals.ErrorText
                  );
                  
                }
              },
              (err: HttpErrorResponse) => {
                this.toastr.error(
                  this.globals.ErrorText,
                  this.globals.ServerErrorMsg
                );
                
              }
            );
     }
     onChangeState(stateId: number) {
          
          let urlAppend = "city-list";
          let bodyData = "state_id="+stateId;
          this._apiService
            .doPOST(urlAppend, bodyData.toString())
            .subscribe(
              (result: any) => {
                //console.log(result);
    
                if (result.responsecode == 200) {
                  this.cityLists = result.city_list;
                // console.log(this.countryLists);
                } else if (result.responsecode == 201) {
                  this.toastr.error(result.responsedetails, "Error");
                } else if (result.responsecode == 401) {
                  this.toastr.error(
                    this.globals.ServerErrorMsg,
                    this.globals.ErrorText
                  );
                  
                } else {
                  this.toastr.error(
                    this.globals.ServerErrorMsg,
                    this.globals.ErrorText
                  );
                  
                }
              },
              (err: HttpErrorResponse) => {
                this.toastr.error(
                  this.globals.ErrorText,
                  this.globals.ServerErrorMsg
                );
                
              }
            );
     }
     // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    saveRegisterData(formValue) {
      
      this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        
        let email = formValue.email;
        let urlAppend = "is-exists-eamil";  
            // to change json to form data
            let bodyData = "email="+email;
            this._apiService
              .doPOST(urlAppend, bodyData.toString())
              .subscribe(
                (result: any) => {
                  console.log(result);
        
                  if (result.responsecode == 200) {
                        localStorage.setItem("registerFrom", JSON.stringify(formValue));       
                        if (localStorage.getItem("registerFrom") != null) {         
                          this.showhide = false;
                        }else{
                          this.showhide = true;
                        }
                    
                  } else if (result.responsecode == 202) {
                    this.toastr.error(result.responsedetails, "Error");
                  } else if (result.responsecode == 401) {
                    this.toastr.error(
                      this.globals.ServerErrorMsg,
                      this.globals.ErrorText
                    );
                    
                  } else {
                    this.toastr.error(
                      this.globals.ServerErrorMsg,
                      this.globals.ErrorText
                    );
                    
                  }
                },
                (err: HttpErrorResponse) => {
                  this.toastr.error(
                    this.globals.ErrorText,
                    this.globals.ServerErrorMsg
                  );
                  
                }
              );

       // console.log(val);
        // localStorage.setItem("registerFrom", JSON.stringify(formValue));       
        // if (localStorage.getItem("registerFrom") != null) {         
        //   this.showhide = false;
        // }else{
        //   this.showhide = true;
        // }
      
    }
    

    getPlanDetails(plan_id) {      
      let urlAppend = "plan-details";
      let bodyData = new URLSearchParams();      
      bodyData.append('plan_id', plan_id);  
      this._apiService
        .doPOST(urlAppend, bodyData.toString())
        .subscribe(
          (result: any) => {
            //console.log(result);
  
            if (result.responsecode == 200) {             
              this.planDetails = result.plan;
            } else if (result.responsecode == 201) {
              this.toastr.error(result.responsedetails, "Error");
            }else if (result.responsecode == 500) {
              this.toastr.error(result.responsedetails);
            } else if (result.responsecode == 202) {
              this.toastr.error(result.responsedetails);
              
            } else {
              this.toastr.error(
                this.globals.ServerErrorMsg,
                this.globals.ErrorText
              );
              
            }
          },
          (err: HttpErrorResponse) => {
            this.toastr.error(
              this.globals.ErrorText,
              this.globals.ServerErrorMsg
            );
            
          }
        );
    }
    goBack() {
      this.showhide = true;    

    }
    returnHome(){
    localStorage.clear();
    this.router.navigate(["/home"]);
    }

    continueRegistrataion() {     
      
      let formValue: any = JSON.parse(localStorage.getItem("registerFrom"));
      let urlAppend = "vet/register-vet";
      let bodyData = new URLSearchParams();
      for (let key in formValue) {        
          bodyData.append(key, formValue[key]);       
      }
      bodyData.append('package', this.package_id);
 
      
  
      this._apiService
        .doPOST(urlAppend, bodyData.toString())
        .subscribe(
          (result: any) => {
            console.log(result);
  
            if (result.responsecode == 200) {  
              localStorage.clear();
              window.location.href = result.go_cardless_url;
            } else if (result.responsecode == 201) {
              this.toastr.error(result.responsedetails, "Error");
            }else if (result.responsecode == 500) {
              this.toastr.error(result.responsedetails);
            } else if (result.responsecode == 202) {
              this.toastr.error(result.responsedetails);
              
            } else {
              this.toastr.error(
                this.globals.ServerErrorMsg,
                this.globals.ErrorText
              );
              
            }
          },
          (err: HttpErrorResponse) => {
            this.toastr.error(
              this.globals.ErrorText,
              this.globals.ServerErrorMsg
            );
            
          }
        );
    }
    
}