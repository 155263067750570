// globals.ts
import { Injectable } from '@angular/core';

@Injectable()
export class Globals {

APIUrlPath: string = 'https://mysmart-pet.com/administrator/api/v1/'; //#staging
// APIUrlPath: string = 'https://805d-115-187-42-88.ngrok.io/api/v1/'; //#staging
 // APIUrlPath: string = 'http://10.0.14.107/petcare/administrator/api/v1/'; //#staging
 // APIUrlPath: string = 'http://10.0.4.235/petcare/backend/admin/api/v1/';
//APIUrlPath: string = 'http://10.0.4.76/petcare_new/backend/admin/api/v1/';

  APIKEY: string = 'PCR2PSM';
  GOOGLEAPIKEY: string = 'AIzaSyCaPHxIzmRBf5qfeSsKOJPhG4Pkzr0ctkc';
  MsgUserTokenExpired: string = 'Session Expired. Please login again to continue.';
  SuccessText: string = '';//'SUCCESS';
  ErrorText: string = '';//'ERROR';
  ServerErrorMsg: string = 'Server Error';
}
