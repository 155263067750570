import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { Globals } from "./../globals";
import { ApiService } from "./../shared/services/api.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'marketplace';

  constructor(
    private _apiService: ApiService,
    private router: Router,
    private globals: Globals,
  ) {}
  

  ngOnInit(){
    /*let urlAppend = "faq-with-category-list";

    // to change json to form data
    let bodyData = "";

    this._apiService
      .doPOST(urlAppend, bodyData.toString())
      .subscribe(
        (result: any) => {
          //console.log(result);

          if (result.responsecode == 200) {

          } else if (result.responsecode == 201) {

          } else if (result.responsecode == 401) {
            
          } else {
            
          }
        },
        (err: HttpErrorResponse) => {
          
        }
      );*/
  }
}
