import { Injectable } from '@angular/core';

@Injectable()
export class CustomService {
    private urlHistory: string;
    private isLoader: boolean;

    constructor() {
        
    }

    windowTop() {
        let scrollToTop = window.setInterval(() => {
            let pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - 20); // how far to scroll on each step
            } else {
                window.clearInterval(scrollToTop);
            }
        }, 16);
    }
}