import {
  Component,
  OnInit,
  HostListener,
  Inject,
  TemplateRef,
} from "@angular/core";
import { Globals } from "./../../globals";
import { ApiService } from "./../../shared/services/api.service";
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import {
  FormBuilder,
  FormsModule,
  FormGroup,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {
  trigger,
  state,
  transition,
  style,
  animate,
} from "@angular/animations";
import { DOCUMENT } from "@angular/common";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { CustomService } from "../../shared/services/custom.service";
import { MustMatch } from "../../shared/must-match.validator";

import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService

declare var $: any;
declare var jquery: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  animations: [
    trigger("fade", [
      state("void", style({ opacity: 0 })),
      transition(":enter", [animate(300)]),
      transition(":leave", [animate(500)]),
    ]),
  ],
})
export class HomeComponent implements OnInit {
  isCollapsed = true;
  urlAppend: string;
  bodyData: any;
  isLoad: boolean = false;
  bannerContent: any = [];
  aboutUsContent: any = [];
  featureForVetContent: any = [];
  yourPetsContent: any = [];
  petWalkContent: any = [];
  petHealthContent: any = [];
  termsAndCondition: any = [];
  featureForPetownerContent: any = [];
  planLists: any;
  modalRef: BsModalRef;
  getInTouchForm: FormGroup;
  productList: any[];
  sliderImages: any[];
  countryLists: any[];
  registerForm: FormGroup;
  contRegisterForm: FormGroup;
  logo;
  image;
  package_id: any;
  showhide = true;
  submitted = false;
  submittedcontact = false;
  planDetails: any[];
  contsubmitted = false;
  privacyContent: any = [];
  localStoreage: any = {};
  randomNumber: any;
  cntry: number;
  promoCodeDetils: any[];

  constructor(
    @Inject(DOCUMENT) document,
    private _apiService: ApiService,
    private toastr: ToastrService, //
    private router: Router,
    private globals: Globals,
    private modalService: BsModalService,
    private customService: CustomService,
    private formBuilder: FormBuilder,
    private ngxService: NgxUiLoaderService
  ) {}

  openModal(template: TemplateRef<any>, id: number) {
    this.package_id = id;
    //console.log(this.package_id);
    this.modalRef = this.modalService.show(
      template,
      Object.assign(
        {},
        { class: "customModal modal-lg", ignoreBackdropClick: true }
      )
    );
    this.getPlanDetails(this.package_id);
  }

  openModalPopup(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign(
        {},
        { class: "customModal modal-lg", ignoreBackdropClick: true }
      )
    );
  }

  ngOnInit() {
    this.customService.windowTop();
    this.getBannerContent();
    this.getAboutUsContent();
    this.getFeatureForPetownerContent();
    this.getYourPetsContent();
    this.getPetWalkContent();
    this.getPetHealthContent();
    this.getFeatureForVetContent();
    this.getProductList();
    this.getCountryList();
    this.getPlanList();
    this.getPrivacyContent();

    this.getTermsAndCondition();
    this.getRandomNumber();
    this.getInTouchForm = this.formBuilder.group({
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      contact_no: [""],
      comment: ["", Validators.required],
      recaptchaReactive: ["", Validators.required],
    });

    let cname = "";
    let fname = "";
    let lname = "";
    let em = "";
    let mn = "";
    let add = "";
    let twn = "";
    let cnty = "";
    let post = "";

    if (localStorage.getItem("registerFrom") != null) {
      this.localStoreage = JSON.parse(localStorage.getItem("registerFrom"));
      cname = this.localStoreage.company_name;
      fname = this.localStoreage.first_name;
      lname = this.localStoreage.last_name;
      em = this.localStoreage.email;
      mn = this.localStoreage.mobile_number;
      add = this.localStoreage.address;
      twn = this.localStoreage.town;
      cnty = this.localStoreage.county;
      post = this.localStoreage.postcode;
      this.cntry = this.localStoreage.country;
    } else {
      this.localStoreage.company_name = "";
      this.localStoreage.first_name = "";
      this.localStoreage.last_name = "";
      this.localStoreage.email = "";
      this.localStoreage.mobile_number = "";
      this.localStoreage.address = "";
      this.localStoreage.town = "";
      this.localStoreage.county = "";
      this.localStoreage.postcode = "";
      this.localStoreage.country = 0;
    }

    this.registerForm = this.formBuilder.group(
      {
        company_name: [cname, Validators.required],
        first_name: [fname, Validators.required],
        last_name: [lname, Validators.required],
        email: [em, [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(6)]],
        confirmPassword: ["", Validators.required],
        mobile_number: [mn, [Validators.required, Validators.minLength(10)]],
        address: [add, Validators.required],
        town: [twn, Validators.required],
        county: [cnty, Validators.required],
        postcode: [post, Validators.required],
        country: [this.cntry, Validators.required],
      },
      {
        validator: MustMatch("password", "confirmPassword"),
      }
    );

    this.contRegisterForm = this.formBuilder.group({
      iaggree: ["", Validators.required],
    });

    // Page Navigation
    const navbar = document.getElementById("navbar");
    navbar.addEventListener('click', function(e:any) {
      e.preventDefault()
      if(e.target.classList.contains('nav-link')) {
        // console.log('Test Click')
        const sectionId = e.target.getAttribute('href')
        // console.log(sectionId)
        const secCords = document.querySelector(sectionId).getBoundingClientRect();
        // console.log(secCords)
        // console.log(window.pageYOffset, 'TOP OFFSET-----')
        window.scrollTo({
          left: secCords.left + window.pageXOffset,
          top: secCords.top + window.pageYOffset - 76,
          behavior: 'smooth'
        })
      }
    })
    // Page Navigation

    // Stickey Header
    const bannerCord = document.querySelector('.banner').getBoundingClientRect()
    const nav_menu = document.getElementById('nav-menu')
    window.addEventListener('scroll', function(e) {
      if(window.scrollY >= bannerCord.top - 78) {
        nav_menu.classList.add('sticky')
      } else {
        nav_menu.classList.remove('sticky')
      }
    })
    // Stickey Header
  }
  @HostListener("window:scroll", ["$event"])
  // onWindowScroll(e) {
  //   if (window.pageYOffset > 130) {
  //     let element = document.getElementById('navbar');
  //     element.classList.add('sticky');
  //   } else {
  //     let element = document.getElementById('navbar');
  //       element.classList.remove('sticky');
  //   }
  // }
  getRandomNumber() {
    this.randomNumber = Math.floor(100000 + Math.random() * 900000);
  }
  getBannerContent() {
    let urlAppend = "promotional-content/getcontent";
    // to change json to form data
    let bodyData = "section_name=Banner";
    this._apiService.doPOST(urlAppend, bodyData.toString()).subscribe(
      (result: any) => {
        //console.log(result);

        if (result.responsecode == 200) {
          this.bannerContent = result.promotional_content;
          //console.log(this.bannerContent);
        } else if (result.responsecode == 201) {
          this.toastr.error(result.responsedetails, "Error");
        } else if (result.responsecode == 401) {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        } else {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(this.globals.ErrorText, this.globals.ServerErrorMsg);
      }
    );
  }
  getAboutUsContent() {
    let urlAppend = "promotional-content/getcontent";
    // to change json to form data
    let bodyData = "section_name=About Us";
    this._apiService.doPOST(urlAppend, bodyData.toString()).subscribe(
      (result: any) => {
        //console.log(result);

        if (result.responsecode == 200) {
          this.aboutUsContent = result.promotional_content;
        } else if (result.responsecode == 201) {
          this.toastr.error(result.responsedetails, "Error");
        } else if (result.responsecode == 401) {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        } else {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(this.globals.ErrorText, this.globals.ServerErrorMsg);
      }
    );
  }
  getFeatureForPetownerContent() {
    let urlAppend = "promotional-content/getcontent";
    // to change json to form data
    let bodyData = "section_name=Features For Pet Owner";
    this._apiService.doPOST(urlAppend, bodyData.toString()).subscribe(
      (result: any) => {
        //console.log(result);

        if (result.responsecode == 200) {
          this.featureForPetownerContent = result.promotional_content;
          //console.log(this.aboutUsContent);
        } else if (result.responsecode == 201) {
          this.toastr.error(result.responsedetails, "Error");
        } else if (result.responsecode == 401) {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        } else {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(this.globals.ErrorText, this.globals.ServerErrorMsg);
      }
    );
  }
  getYourPetsContent() {
    let urlAppend = "promotional-content/getcontent";
    // to change json to form data
    let bodyData = "section_name=Your Pets";
    this._apiService.doPOST(urlAppend, bodyData.toString()).subscribe(
      (result: any) => {
        //console.log(result);

        if (result.responsecode == 200) {
          this.yourPetsContent = result.promotional_content;
          //console.log(this.aboutUsContent);
        } else if (result.responsecode == 201) {
          this.toastr.error(result.responsedetails, "Error");
        } else if (result.responsecode == 401) {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        } else {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(this.globals.ErrorText, this.globals.ServerErrorMsg);
      }
    );
  }
  getPetWalkContent() {
    let urlAppend = "promotional-content/getcontent";
    // to change json to form data
    let bodyData = "section_name=Pet Walk";
    this._apiService.doPOST(urlAppend, bodyData.toString()).subscribe(
      (result: any) => {
        //console.log(result);

        if (result.responsecode == 200) {
          this.petWalkContent = result.promotional_content;
          //console.log(this.aboutUsContent);
        } else if (result.responsecode == 201) {
          this.toastr.error(result.responsedetails, "Error");
        } else if (result.responsecode == 401) {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        } else {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(this.globals.ErrorText, this.globals.ServerErrorMsg);
      }
    );
  }
  getPetHealthContent() {
    let urlAppend = "promotional-content/getcontent";
    // to change json to form data
    let bodyData = "section_name=Pet Health";
    this._apiService.doPOST(urlAppend, bodyData.toString()).subscribe(
      (result: any) => {
        //console.log(result);

        if (result.responsecode == 200) {
          this.petHealthContent = result.promotional_content;
          //console.log(this.aboutUsContent);
        } else if (result.responsecode == 201) {
          this.toastr.error(result.responsedetails, "Error");
        } else if (result.responsecode == 401) {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        } else {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(this.globals.ErrorText, this.globals.ServerErrorMsg);
      }
    );
  }
  getTermsAndCondition() {
    let urlAppend = "promotional-content/getcontent";
    // to change json to form data
    let bodyData = "section_name=	Terms And Conditions";
    this._apiService.doPOST(urlAppend, bodyData.toString()).subscribe(
      (result: any) => {
        //console.log(result);

        if (result.responsecode == 200) {
          this.termsAndCondition = result.promotional_content;
          //console.log(this.aboutUsContent);
        } else if (result.responsecode == 201) {
          this.toastr.error(result.responsedetails, "Error");
        } else if (result.responsecode == 401) {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        } else {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(this.globals.ErrorText, this.globals.ServerErrorMsg);
      }
    );
  }
  getPrivacyContent() {
    let urlAppend = "marketing-privacy-policy";
    // to change json to form data
    let bodyData = "";
    this._apiService.doPOST(urlAppend, bodyData.toString()).subscribe(
      (result: any) => {
        // console.log(result);

        if (result.responsecode == 200) {
          this.privacyContent = result.data;
        } else if (result.responsecode == 201) {
          this.toastr.error(result.responsedetails, "Error");
        } else if (result.responsecode == 401) {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        } else {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(this.globals.ErrorText, this.globals.ServerErrorMsg);
      }
    );
  }
  getFeatureForVetContent() {
    let urlAppend = "promotional-content/getcontent";
    // to change json to form data
    let bodyData = "section_name=Features For Vet";
    this._apiService.doPOST(urlAppend, bodyData.toString()).subscribe(
      (result: any) => {
        //console.log(result);

        if (result.responsecode == 200) {
          this.featureForVetContent = result.promotional_content;
          this.sliderImages = result.promotional_content.promotional_images;
          //console.log(this.sliderImages);
        } else if (result.responsecode == 201) {
          this.toastr.error(result.responsedetails, "Error");
        } else if (result.responsecode == 401) {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        } else {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(this.globals.ErrorText, this.globals.ServerErrorMsg);
      }
    );
  }

  // convenience getter for easy access to form fields
  get fff() {
    return this.getInTouchForm.controls;
  }
  saveGetInTouchAction(formValue) {
    this.submittedcontact = true;

    // stop here if form is invalid
    if (this.getInTouchForm.invalid) {
      return;
    }

    this.ngxService.start();
    let urlAppend = "promotional-content/getintouch";

    // to change json to form data
    let bodyData = new URLSearchParams();
    for (let key in formValue) {
      bodyData.set(key, formValue[key]);
    }
    //console.log(bodyData.toString());

    this._apiService.doPOST(urlAppend, bodyData.toString()).subscribe(
      (result: any) => {
        //console.log(result);

        if (result.responsecode == 200) {
          this.ngxService.stop();
          /*this.profileData.old_password = result.vet_details.old_password;
                  this.profileData.company_intro = result.vet_details.company_intro;
                  this.profileData.company_video = result.vet_details.company_video;*/

          this.getInTouchForm.reset();

          this.toastr.success(
            result.responsedetails,
            "Thank you for your comments. Our team will contact you soon!"
          );
        } else if (result.responsecode == 201) {
          this.ngxService.stop();
          this.toastr.error(result.responsedetails, "Error");
        } else if (result.responsecode == 401) {
          this.ngxService.stop();
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        } else {
          this.ngxService.stop();
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        }
      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        this.toastr.error(this.globals.ErrorText, this.globals.ServerErrorMsg);
      }
    );
  }
  getProductList() {
    let urlAppend = "product/product-list";
    // to change json to form data
    let bodyData = "";
    this._apiService.doPOST(urlAppend, bodyData.toString()).subscribe(
      (result: any) => {
        //console.log(result);

        if (result.responsecode == 200) {
          this.productList = result.product_list;
        } else if (result.responsecode == 201) {
          this.toastr.error(result.responsedetails, "Error");
        } else if (result.responsecode == 401) {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        } else {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(this.globals.ErrorText, this.globals.ServerErrorMsg);
      }
    );
  }
  getCountryList() {
    let urlAppend = "country-list";
    let bodyData = "";
    this._apiService.doPOST(urlAppend, bodyData.toString()).subscribe(
      (result: any) => {
        //console.log(result);

        if (result.responsecode == 200) {
          this.countryLists = result.country_list;
          // console.log(this.countryLists);
        } else if (result.responsecode == 201) {
          this.toastr.error(result.responsedetails, "Error");
        } else if (result.responsecode == 401) {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        } else {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(this.globals.ErrorText, this.globals.ServerErrorMsg);
      }
    );
  }

  readThis(inputValue: any, type): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      if (type == "image") {
        this.image = myReader.result;
        //this.profileData.profile_image = this.image; //for image browes preview
      } else {
        this.logo = myReader.result;
        //this.profileData.company_logo = this.logo; //for logo browes preview
      }
    };
    //if(myReader.result)
    myReader.readAsDataURL(file);
  }
  changeListener($event, type = "logo"): void {
    if (event) this.readThis($event.target, type);
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  // convenience getter for easy access to form fields
  get ff() {
    return this.contRegisterForm.controls;
  }

  saveRegisterData(formValue) {
    localStorage.removeItem("promocodeValue");
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    this.ngxService.start();

    let email = formValue.email;
    let urlAppend = "is-exists-eamil";
    // to change json to form data
    let bodyData = "email=" + email;
    this._apiService.doPOST(urlAppend, bodyData.toString()).subscribe(
      (result: any) => {
        console.log(result);

        if (result.responsecode == 200) {
          this.ngxService.stop();
          this.localStoreage = localStorage.setItem(
            "registerFrom",
            JSON.stringify(formValue)
          );

          //localStorage.setItem('first_name', JSON.stringify(formValue.first_name));

          if (localStorage.getItem("registerFrom") != null) {
            this.showhide = false;
          } else {
            this.showhide = true;
          }
        } else if (result.responsecode == 202) {
          this.ngxService.stop();
          this.toastr.error(result.responsedetails, "Error");
        } else if (result.responsecode == 401) {
          this.ngxService.stop();
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        } else {
          this.ngxService.stop();
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        }
      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        this.toastr.error(this.globals.ErrorText, this.globals.ServerErrorMsg);
      }
    );
  }
  continueRegistrataion(frmValue) {
    this.contsubmitted = true;
    // stop here if form is invalid
    if (this.contRegisterForm.invalid) {
      return;
    }

    this.ngxService.start();
    let fromValue: any = JSON.parse(localStorage.getItem("registerFrom"));
    let urlAppend = "vet/register-vet";
    let bodyData = new URLSearchParams();
    for (let key in fromValue) {
      bodyData.append(key, fromValue[key]);
    }

    bodyData.append("package", this.package_id);
    bodyData.append(
      "promocode",
      JSON.parse(localStorage.getItem("promocodeValue"))
    );
    //console.log(bodyData.toString());

    this._apiService.doPOST(urlAppend, bodyData.toString()).subscribe(
      (result: any) => {
        //console.log(result);

        if (result.responsecode == 200) {
          this.ngxService.stop();
          //localStorage.clear();
          window.location.href = result.go_cardless_url;
        } else if (result.responsecode == 201) {
          this.ngxService.stop();
          this.toastr.error(result.responsedetails, "Error");
        } else if (result.responsecode == 500) {
          this.ngxService.stop();
          this.toastr.error(result.responsedetails);
        } else if (result.responsecode == 202) {
          this.ngxService.stop();
          this.toastr.error(result.responsedetails);
        } else {
          this.ngxService.stop();
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        }
      },
      (err: HttpErrorResponse) => {
        this.ngxService.stop();
        this.toastr.error(this.globals.ErrorText, this.globals.ServerErrorMsg);
      }
    );
  }
  getPlanList() {
    let urlAppend = "subscription/packages-list";
    let bodyData = "";
    this._apiService.doPOST(urlAppend, bodyData.toString()).subscribe(
      (result: any) => {
        //console.log(result);

        if (result.responsecode == 200) {
          this.planLists = result.packages;
        } else if (result.responsecode == 201) {
          this.toastr.error(result.responsedetails, "Error");
        } else if (result.responsecode == 401) {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        } else {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(this.globals.ErrorText, this.globals.ServerErrorMsg);
      }
    );
  }

  getPlanDetails(plan_id) {
    let urlAppend = "plan-details";
    let bodyData = new URLSearchParams();
    bodyData.append("plan_id", plan_id);
    this._apiService.doPOST(urlAppend, bodyData.toString()).subscribe(
      (result: any) => {
        //console.log(result);

        if (result.responsecode == 200) {
          this.planDetails = result.plan;
        } else if (result.responsecode == 201) {
          this.toastr.error(result.responsedetails, "Error");
        } else if (result.responsecode == 500) {
          this.toastr.error(result.responsedetails);
        } else if (result.responsecode == 202) {
          this.toastr.error(result.responsedetails);
        } else {
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(this.globals.ErrorText, this.globals.ServerErrorMsg);
      }
    );
  }
  goBack() {
    this.showhide = true;
  }
  returnHome() {
    //localStorage.clear();
    this.router.navigate(["/home"]);
  }

  checkPromoCode(val, price) {
    this.ngxService.start();
    let urlAppend = "check-promo-code";
    let bodyData = new URLSearchParams();
    bodyData.append("promocode", val);
    bodyData.append("price", price);
    this._apiService.doPOST(urlAppend, bodyData.toString()).subscribe(
      (result: any) => {
        if (result.responsecode == 200) {
          this.ngxService.stop();
          this.promoCodeDetils = result.promocode_data;
          this.toastr.success(result.responsedetails, "");
          this.localStoreage = localStorage.setItem(
            "promocodeValue",
            JSON.stringify(val)
          );
        } else if (result.responsecode == 201) {
          this.ngxService.stop();
          this.toastr.error(result.responsedetails, "Error");
        } else if (result.responsecode == 500) {
          this.ngxService.stop();
          this.toastr.error(result.responsedetails);
        } else if (result.responsecode == 202) {
          this.ngxService.stop();
          this.toastr.error(result.responsedetails);
          this.promoCodeDetils = result.promocode_data;
        } else {
          this.ngxService.stop();
          this.toastr.error(
            this.globals.ServerErrorMsg,
            this.globals.ErrorText
          );
        }
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(this.globals.ErrorText, this.globals.ServerErrorMsg);
      }
    );
  }
}
