import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../layout/home/home.component';
import { PrivacyComponent } from '../layout/privacy/privacy.component';
import { MessageComponent } from '../layout/message/message.component';
import { RegisterVetComponent } from '../layout/registervet/registervet.component';


const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "home",component: HomeComponent, pathMatch: "full"},
  { path: "privacy-policy",component: PrivacyComponent, pathMatch: "full"},
  { path: "message",component: MessageComponent, pathMatch: "full"},
  { path: "register-as-vet",component: RegisterVetComponent, pathMatch: "full"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
