import { Component, OnInit, HostListener, Inject, TemplateRef} from '@angular/core';
import { Globals } from "./../../globals";
import { ApiService } from "./../../shared/services/api.service";
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { FormsModule, FormGroup, FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { trigger, state, transition, style, animate } from '@angular/animations'; 
import { DOCUMENT } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CustomService } from "../../shared/services/custom.service";



declare var $: any;
declare var jquery: any;

@Component({
    selector: 'app-home',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss'],
    animations:[ 
      trigger('fade',
      [ 
        state('void', style({ opacity : 0})),
        transition(':enter',[ animate(300)]),
        transition(':leave',[ animate(500)]),
      ]
  )]
})

export class PrivacyComponent implements OnInit {

    isCollapsed = true;    
    urlAppend: string;
    bodyData: any;
    isLoad: boolean = false;    
    logo;
    image;
    privacyContent: any=[];

    constructor(@Inject(DOCUMENT) document,
        private _apiService: ApiService,
        private toastr: ToastrService, //
        private router: Router,
        private globals: Globals,
        private modalService: BsModalService,
        private customService: CustomService
    ) {}

    ngOnInit() { 
      
      this.customService.windowTop();  
      this.getPrivacyContent();

    }
    
    @HostListener('window:scroll', ['$event'])
    onWindowScroll(e) {
      if (window.pageYOffset > 130) {
        let element = document.getElementById('navbar');
        element.classList.add('sticky');
      } else {
        let element = document.getElementById('navbar');
          element.classList.remove('sticky'); 
      }
    }

    ngAfterViewInit() {        
         // this.toastr.success('Register Successful, please login');      
     }
     
     getPrivacyContent(){        
      let urlAppend = "marketing-privacy-policy";  
      // to change json to form data
      let bodyData = "";
      this._apiService
        .doPOST(urlAppend, bodyData.toString())
        .subscribe(
          (result: any) => {
            console.log(result);
  
            if (result.responsecode == 200) {
              this.privacyContent = result.data;
             
          } else if (result.responsecode == 201) {
            this.toastr.error(result.responsedetails, "Error");
          } else if (result.responsecode == 401) {
            this.toastr.error(
              this.globals.ServerErrorMsg,
              this.globals.ErrorText
            );
            
          } else {
            this.toastr.error(
              this.globals.ServerErrorMsg,
              this.globals.ErrorText
            );
            
          }
        },
        (err: HttpErrorResponse) => {
          this.toastr.error(
            this.globals.ErrorText,
            this.globals.ServerErrorMsg
          );
          
        }
        );
  }
    
}